<template>
    <section class="data-list-container">

        <v-row class="mb-2">
            <v-col>
                <new-button class="mr-5" @click="openAddNew">Adauga atribut nou</new-button>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
                <regenerate-product-attribute-pivot/>
            </v-col>
        </v-row>

        <v-card>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :hide-default-footer="true"
                    :items="paginatedItems.data"
                    @update:sort-by="updateSortField"
                    @update:sort-desc="updateSortType"
                >

                    <template v-slot:header="{props}">
                        <tr>
                            <th v-for="header in props.headers" :key="header.text">
                                <template v-if="header.value === 'name'">
                                    <filter-input :filter-name="header.value" placeholder="Filter..."
                                                  @filter="updateFilter"/>
                                </template>

                                <template v-if="header.value === 'group.name'">
                                    <attribute-group-filter filter-name="group" @filter="updateFilter"/>
                                </template>

                                <template v-if="header.value === 'actions'">
                                    <div class="text-center">
                                        <help-icon position="left"
                                                   text="Atributele se pot sterge doar daca nu sunt folosite niciude"/>
                                    </div>
                                </template>

                            </th>
                        </tr>
                    </template>


                    <template v-slot:item.has_stock="{item}">
                        <template v-if="item.has_stock">Da</template>
                        <template v-else>Nu</template>
                    </template>

                    <template v-slot:item.actions="{item}">
                        <edit-button @click="editItem(item)"></edit-button>
                        <attribute-button :to="{name: 'pm-attributes-values', params: { id: item.id} }"/>
                        <delete-button v-if="item.deletable" @delete="deleteItem(item)"></delete-button>
                    </template>


                </v-data-table>
            </v-card-text>
            <v-card-actions>

                <v-pagination
                    :length="lastPage"
                    :total-visible="5"
                    :value="currentPage"
                    color="#76bd43"
                    @input="changePage"
                ></v-pagination>
            </v-card-actions>
        </v-card>


    </section>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'
import DeleteButton from '@/components/general-form/DeleteButton'
import FilterInput from '@/components/general-form/FilterInput'
import FilterByComponentType from '@/views/ProductManagement/Attributes/components/FilterByComponentType'
import HelpIcon from '@/components/HelpIcon'
import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'
import CategoryFilter from '@/views/Products/components/Filters/CategoryFilter'
import AttributeButton from '@/components/general-form/AttributeButton'
import AttributeGroupFilter from '@/components/filters/AttributeGroupFilter'
import RegenerateProductAttributePivot from '@/components/buttons/RegenerateProductAttributePivot'

export default {
    name: 'ComponentTypeList',
    components: {
        AttributeGroupFilter,
        AttributeButton,
        CategoryFilter,
        ProductCategorySelector,
        HelpIcon,
        FilterByComponentType,
        FilterInput,
        DeleteButton,
        EditButton,
        NewButton,
        RegenerateProductAttributePivot
    },
    data() {
        return {
            headers: [
                {
                    text: 'Denumire',
                    value: 'name',
                    sortable: false
                },
                {
                    text: 'Grup',
                    value: 'group.name',
                    sortable: false
                },
                {
                    text: '',
                    value: 'actions',
                    width: '150',
                    sortable: false
                }
            ],
            sortFiled: [],
            sortIsDesc: []
        }
    },
    computed: {
        lastPage() {
            return this.$store.getters['attributes/last_page']
        },
        paginatedItems() {
            return this.$store.getters['attributes/paginatedData']
        },
        currentPage() {
            return this.$store.getters['attributes/currentPage']
        },
        sortParam() {
            if (this.sortFiled.length) {
                const sortString = []
                for (let i = 0; i < this.sortFiled.length; i++) {
                    sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
                }
                return sortString.join(',')
            }
            return ''
        }
    },
    methods: {
        openAddNew() {
            this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
                component: 'add-base-product-attribute',
                title: 'Adauga atribut nou'
            })
        },
        editItem(item) {
            this.$store.dispatch('attributes/editBaseProductItem', item)
        },
        changePage(newPage) {
            this.$store.dispatch('attributes/changePage', newPage)
        },
        updateSortField(field) {
            this.sortFiled = field
        },
        updateSortType(isDesc) {
            this.sortIsDesc = isDesc
        },
        updateFilter(searchValue, filterName) {
            if (searchValue === '') {
                this.$store.dispatch('attributes/deleteFilter', filterName)
            } else {
                this.$store.dispatch('attributes/addFilter', {
                    filterName,
                    searchValue
                })
            }
        },
        loadItems() {
            this.$store.dispatch('attributes/loadItems')
        },
        deleteItem(item) {
            this.$store.dispatch('attributes/deleteItem', item.id)
        }
    },
    watch: {
        sortParam(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$store.dispatch('attributes/changeSort', newValue)
            }
        }
    },
    created() {
        this.loadItems()
    }
}
</script>
